import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorHandlingModule, NoAccessComponent, NotFoundComponent, PRODUCT_NAME_TOKEN } from '@skykick/core';

const APP_ROUTES: Routes = [
    {
        path: '',
        loadChildren: () => import('./pci/pci.module').then((m) => m.PciModule),
    },
    {
        path: 'no-access',
        component: NoAccessComponent,
    },
    {
        path: '**',
        component: NotFoundComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(APP_ROUTES, {
            enableTracing: false,
            paramsInheritanceStrategy: 'always',
            relativeLinkResolution: 'legacy',
        }),
        ErrorHandlingModule,
    ],
    exports: [RouterModule],
    providers: [{ provide: PRODUCT_NAME_TOKEN, useValue: 'Partner Center' }],
})
export class AppRoutingModule { }
