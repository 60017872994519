import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Connection, IPartnerCenterConnectionService, PartnerProfile } from '@skykick/partner-center';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PartnerCenterConnectionService extends IPartnerCenterConnectionService {
    private BaseUrl = `${environment.apis.prefix}/${environment.apis.version}`;

    constructor(private client: HttpClient) { super(); }

    getAuthorizeUri(): Observable<string> {
        return this.client.get<string>(this.BaseUrl + '/authorize/uri');
    }

    getConnection(): Observable<Connection> {
        return this.client.get<Connection>(this.BaseUrl + '/connection');
    }

    removeConnection(): Observable<void> {
        return this.client.delete<void>(this.BaseUrl + '/connection');
    }

    saveConnection(connection: Connection): Observable<Connection> {
        return this.client.post<Connection>(this.BaseUrl + '/connection', connection);
    }

    getPartnerProfile(): Observable<PartnerProfile> {
        return this.client.get<PartnerProfile>(this.BaseUrl + '/partner/profile');
    }
}
