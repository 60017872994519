import { SkyKickUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { PartnerCenterEnvironment } from './partner-center-environment';

export const environment: PartnerCenterEnvironment = {
    production: true,
    userProvider: SkyKickUserProvider,
    apis: {
        prefix: '/api',
        version: 'v1'
    },
};
