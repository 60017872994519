// This is needed by the class-transformer package at a global scope
import 'reflect-metadata';

import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { AppId, NavigationArea, PlatformNavigationComponent, RouteResolverService } from '@skykick/core';
import { Observable } from 'rxjs';
import { AppService } from './app.service';

@Component({
    /* tslint:disable-next-line:component-selector */
    selector: '[sk-root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
    portalUri: string;

    get loading$(): Observable<boolean> {
        return this.appService.loading$;
    }

    constructor(
        private appService: AppService,
        private routeResolverService: RouteResolverService
    ) {
        this.portalUri = this.routeResolverService.generateRatRoute(AppId.Portal);
    }

    @ViewChild(PlatformNavigationComponent)
    public platformNav: PlatformNavigationComponent;

    ngAfterViewInit(): void {
        this.platformNav.SkNavigationArea = NavigationArea.Admin;
    }
}
