import { ClassProvider, ExistingProvider, Provider, Type } from '@angular/core';

import { AbstractConstructor } from './abstract-constructor';
import { SkyKickAngularProviderConfig } from './skykick-angular-provider-config';
import { SkyKickAngularProviderMapper } from './skykick-angular-provider-mapper';

export interface ISkyKickAngularProvider<TAbstract> extends SkyKickAngularProviderMapper {
    impl: Type<TAbstract>;
    abstraction: AbstractConstructor<TAbstract>;
    config: SkyKickAngularProviderConfig;
}

export class SkyKickAngularProvider<TAbstract> implements ISkyKickAngularProvider<TAbstract> {
    constructor(
        public impl: Type<TAbstract>,
        public abstraction: AbstractConstructor<TAbstract>,
        public config: SkyKickAngularProviderConfig = { deps: [], alreadyExists: false }
    ) {
    }

    asProvider(): Provider {
        const provider: Provider = {
            provide: this.abstraction as any,
            deps: this.config.deps
        };

        if (this.config.alreadyExists) {
            (<ExistingProvider>provider).useExisting = this.impl;
        } else {
            (<ClassProvider>provider).useClass = this.impl;
        }

        return provider;
    }
}
