import { Provider } from '@angular/core';

import { ISkyKickAngularProvider } from './skykick-angular-provider';
import { SkyKickAngularProviderMapper } from './skykick-angular-provider-mapper';

export class SkyKickProvidersBuilder {
    private providers: SkyKickAngularProviderMapper[] = [];

    add<TAbstract>(provider: ISkyKickAngularProvider<TAbstract>) {
        this.providers.push(provider);
        return this;
    }

    build(): Provider[] {
        return this.providers.map(x => x.asProvider());
    }
}
