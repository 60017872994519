import { Type } from '@angular/core';

import { AbstractConstructor } from './abstract-constructor';
import { ISkyKickAngularProvider, SkyKickAngularProvider } from './skykick-angular-provider';
import { SkyKickAngularProviderConfig } from './skykick-angular-provider-config';

export function createSkyKickAngularProvider<T>(
    impl: Type<T>,
    abstraction: AbstractConstructor<T>,
    config: SkyKickAngularProviderConfig = { deps: [], alreadyExists: false }
): ISkyKickAngularProvider<T> {
    return new SkyKickAngularProvider(impl, abstraction, config);
}
