import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    private _loading = new BehaviorSubject<boolean>(false);
    loading$: Observable<boolean> = this._loading.asObservable();

    private loadingQueue: Set<string> = new Set<string>();

    public requestLoading(key: string) {
        this.loadingQueue.add(key);
        this.computeLoading();
    }

    public releaseLoading(key: string) {
        this.loadingQueue.delete(key);
        this.computeLoading();
    }

    private computeLoading() {
        const processesAreLoading = this.loadingQueue.size > 0 ?
            true :
            false;
        this._loading.next(processesAreLoading);
    }
}
