import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { delay, dematerialize, materialize, mergeMap } from 'rxjs/operators';

export class LocalPlatformServicesStub implements HttpInterceptor {
    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        /* simulate call to server */
        return of(null)
            .pipe(
                mergeMap(() => this.handleRoute(req, next)),
                materialize(),
                delay(500),
                dematerialize()
            );
    }

    private handleRoute(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const { url } = req;

        switch (true) {
            case url === 'https://route.skykick.dev/pipe/papi/banners':
                return this.ok([{ 'enabled': true, 'type': 'test', 'message': 'This is a test banner. You can close me and it will persist in LocalStorage' }]);
            case url === 'https://route.skykick.dev/pipe/papi/partner/logo':
                return this.ok({});
            case url === 'https://route.skykick.dev/pipe/papi/partner/admin/24114725-9425-e311-a540-b4b52f67d6de/authenticationsettings':
                return this.ok({ authenticationType: 0 });
            case url === 'https://route.skykick.dev/pipe/papi/usermanagement/request-mfa-activation':
                return this.ok({});
            default:
                return next.handle(req);
        }
    }

    private ok<T>(body?: T): Observable<HttpResponse<T>> {
        return of(new HttpResponse<T>({ body, status: 200 }));
    }
}

export const localPlatformServicesStubProvider = { provide: HTTP_INTERCEPTORS, useClass: LocalPlatformServicesStub, multi: true };
